<template>
  <div style="pointer-events: none;">

    <img v-show="color === 'red'"
         src='../assets/red connect 4 piece.png'
         style="width: 100%; height: 100%"
         class="drop"
    >

    <img v-show="color === 'yellow'"
         src='../assets/yellow connect 4 piece.png'
         style="width: 100%; height: 100%"
         class="drop"
    >

    <img v-show="color === ''"
         src='../assets/blank connect 4 piece.png'
         style="width: 100%; height: 100%"
         class="drop"
    >
  </div>
</template>

<style scoped>

/*css drop animation class*/
.drop {
  animation: drop;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

/*drop keyframes generated by "MiscJS/CSS drop generator.js"*/
@keyframes drop {
  0.000% {transform: translateY(-800.000px)}
  0.455% {transform: translateY(-799.500px)}
  0.909% {transform: translateY(-798.500px)}
  1.364% {transform: translateY(-797.000px)}
  1.818% {transform: translateY(-795.000px)}
  2.273% {transform: translateY(-792.500px)}
  2.727% {transform: translateY(-789.500px)}
  3.182% {transform: translateY(-786.000px)}
  3.636% {transform: translateY(-782.000px)}
  4.091% {transform: translateY(-777.500px)}
  4.545% {transform: translateY(-772.500px)}
  5.000% {transform: translateY(-767.000px)}
  5.455% {transform: translateY(-761.000px)}
  5.909% {transform: translateY(-754.500px)}
  6.364% {transform: translateY(-747.500px)}
  6.818% {transform: translateY(-740.000px)}
  7.273% {transform: translateY(-732.000px)}
  7.727% {transform: translateY(-723.500px)}
  8.182% {transform: translateY(-714.500px)}
  8.636% {transform: translateY(-705.000px)}
  9.091% {transform: translateY(-695.000px)}
  9.545% {transform: translateY(-684.500px)}
  10.000% {transform: translateY(-673.500px)}
  10.455% {transform: translateY(-662.000px)}
  10.909% {transform: translateY(-650.000px)}
  11.364% {transform: translateY(-637.500px)}
  11.818% {transform: translateY(-624.500px)}
  12.273% {transform: translateY(-611.000px)}
  12.727% {transform: translateY(-597.000px)}
  13.182% {transform: translateY(-582.500px)}
  13.636% {transform: translateY(-567.500px)}
  14.091% {transform: translateY(-552.000px)}
  14.545% {transform: translateY(-536.000px)}
  15.000% {transform: translateY(-519.500px)}
  15.455% {transform: translateY(-502.500px)}
  15.909% {transform: translateY(-485.000px)}
  16.364% {transform: translateY(-467.000px)}
  16.818% {transform: translateY(-448.500px)}
  17.273% {transform: translateY(-429.500px)}
  17.727% {transform: translateY(-410.000px)}
  18.182% {transform: translateY(-390.000px)}
  18.636% {transform: translateY(-369.500px)}
  19.091% {transform: translateY(-348.500px)}
  19.545% {transform: translateY(-327.000px)}
  20.000% {transform: translateY(-305.000px)}
  20.455% {transform: translateY(-282.500px)}
  20.909% {transform: translateY(-259.500px)}
  21.364% {transform: translateY(-236.000px)}
  21.818% {transform: translateY(-212.000px)}
  22.273% {transform: translateY(-187.500px)}
  22.727% {transform: translateY(-162.500px)}
  23.182% {transform: translateY(-137.000px)}
  23.636% {transform: translateY(-111.000px)}
  24.091% {transform: translateY(-84.500px)}
  24.545% {transform: translateY(-57.500px)}
  25.000% {transform: translateY(-30.000px)}
  25.455% {transform: translateY(-2.000px)}
  25.909% {transform: translateY(0.000px)}
  26.364% {transform: translateY(-5.800px)}
  26.818% {transform: translateY(-11.100px)}
  27.273% {transform: translateY(-15.900px)}
  27.727% {transform: translateY(-20.200px)}
  28.182% {transform: translateY(-24.000px)}
  28.636% {transform: translateY(-27.300px)}
  29.091% {transform: translateY(-30.100px)}
  29.545% {transform: translateY(-32.400px)}
  30.000% {transform: translateY(-34.200px)}
  30.455% {transform: translateY(-35.500px)}
  30.909% {transform: translateY(-36.300px)}
  31.364% {transform: translateY(-36.600px)}
  31.818% {transform: translateY(-36.400px)}
  32.273% {transform: translateY(-35.700px)}
  32.727% {transform: translateY(-34.500px)}
  33.182% {transform: translateY(-32.800px)}
  33.636% {transform: translateY(-30.600px)}
  34.091% {transform: translateY(-27.900px)}
  34.545% {transform: translateY(-24.700px)}
  35.000% {transform: translateY(-21.000px)}
  35.455% {transform: translateY(-16.800px)}
  35.909% {transform: translateY(-12.100px)}
  36.364% {transform: translateY(-6.900px)}
  36.818% {transform: translateY(-1.200px)}
  37.273% {transform: translateY(0.000px)}
  37.727% {transform: translateY(-1.340px)}
  38.182% {transform: translateY(-2.180px)}
  38.636% {transform: translateY(-2.520px)}
  39.091% {transform: translateY(-2.360px)}
  39.545% {transform: translateY(-1.700px)}
  40.000% {transform: translateY(-0.540px)}
  40.455% {transform: translateY(0.000px)}
  40.909% {transform: translateY(-0.432px)}
  41.364% {transform: translateY(-0.364px)}
  41.818% {transform: translateY(0.000px)}
  42.273% {transform: translateY(-0.214px)}
  42.727% {transform: translateY(0.000px)}
  43.182% {transform: translateY(-0.157px)}
  43.636% {transform: translateY(0.000px)}
  44.091% {transform: translateY(-0.169px)}
  44.545% {transform: translateY(0.000px)}
  45.000% {transform: translateY(-0.166px)}
  45.455% {transform: translateY(0.000px)}
  45.909% {transform: translateY(-0.167px)}
  46.364% {transform: translateY(0.000px)}
  46.818% {transform: translateY(-0.167px)}
  47.273% {transform: translateY(0.000px)}
  47.727% {transform: translateY(-0.167px)}
  48.182% {transform: translateY(0.000px)}
  48.636% {transform: translateY(-0.167px)}
  49.091% {transform: translateY(0.000px)}
  49.545% {transform: translateY(-0.167px)}
  50.000% {transform: translateY(0.000px)}
  50.455% {transform: translateY(-0.167px)}
  50.909% {transform: translateY(0.000px)}
  51.364% {transform: translateY(-0.167px)}
  51.818% {transform: translateY(0.000px)}
  52.273% {transform: translateY(-0.167px)}
  52.727% {transform: translateY(0.000px)}
  53.182% {transform: translateY(-0.167px)}
  53.636% {transform: translateY(0.000px)}
  54.091% {transform: translateY(-0.167px)}
  54.545% {transform: translateY(0.000px)}
  55.000% {transform: translateY(-0.167px)}
  55.455% {transform: translateY(0.000px)}
  55.909% {transform: translateY(-0.167px)}
  56.364% {transform: translateY(0.000px)}
  56.818% {transform: translateY(-0.167px)}
  57.273% {transform: translateY(0.000px)}
  57.727% {transform: translateY(-0.167px)}
  58.182% {transform: translateY(0.000px)}
  58.636% {transform: translateY(-0.167px)}
  59.091% {transform: translateY(0.000px)}
  59.545% {transform: translateY(-0.167px)}
  60.000% {transform: translateY(0.000px)}
  60.455% {transform: translateY(-0.167px)}
  60.909% {transform: translateY(0.000px)}
  61.364% {transform: translateY(-0.167px)}
  61.818% {transform: translateY(0.000px)}
  62.273% {transform: translateY(-0.167px)}
  62.727% {transform: translateY(0.000px)}
  63.182% {transform: translateY(-0.167px)}
  63.636% {transform: translateY(0.000px)}
  64.091% {transform: translateY(-0.167px)}
  64.545% {transform: translateY(0.000px)}
  65.000% {transform: translateY(-0.167px)}
  65.455% {transform: translateY(0.000px)}
  65.909% {transform: translateY(-0.167px)}
  66.364% {transform: translateY(0.000px)}
  66.818% {transform: translateY(-0.167px)}
  67.273% {transform: translateY(0.000px)}
  67.727% {transform: translateY(-0.167px)}
  68.182% {transform: translateY(0.000px)}
  68.636% {transform: translateY(-0.167px)}
  69.091% {transform: translateY(0.000px)}
  69.545% {transform: translateY(-0.167px)}
  70.000% {transform: translateY(0.000px)}
  70.455% {transform: translateY(-0.167px)}
  70.909% {transform: translateY(0.000px)}
  71.364% {transform: translateY(-0.167px)}
  71.818% {transform: translateY(0.000px)}
  72.273% {transform: translateY(-0.167px)}
  72.727% {transform: translateY(0.000px)}
  73.182% {transform: translateY(-0.167px)}
  73.636% {transform: translateY(0.000px)}
  74.091% {transform: translateY(-0.167px)}
  74.545% {transform: translateY(0.000px)}
  75.000% {transform: translateY(-0.167px)}
  75.455% {transform: translateY(0.000px)}
  75.909% {transform: translateY(-0.167px)}
  76.364% {transform: translateY(0.000px)}
  76.818% {transform: translateY(-0.167px)}
  77.273% {transform: translateY(0.000px)}
  77.727% {transform: translateY(-0.167px)}
  78.182% {transform: translateY(0.000px)}
  78.636% {transform: translateY(-0.167px)}
  79.091% {transform: translateY(0.000px)}
  79.545% {transform: translateY(-0.167px)}
  80.000% {transform: translateY(0.000px)}
  80.455% {transform: translateY(-0.167px)}
  80.909% {transform: translateY(0.000px)}
  81.364% {transform: translateY(-0.167px)}
  81.818% {transform: translateY(0.000px)}
  82.273% {transform: translateY(-0.167px)}
  82.727% {transform: translateY(0.000px)}
  83.182% {transform: translateY(-0.167px)}
  83.636% {transform: translateY(0.000px)}
  84.091% {transform: translateY(-0.167px)}
  84.545% {transform: translateY(0.000px)}
  85.000% {transform: translateY(-0.167px)}
  85.455% {transform: translateY(0.000px)}
  85.909% {transform: translateY(-0.167px)}
  86.364% {transform: translateY(0.000px)}
  86.818% {transform: translateY(-0.167px)}
  87.273% {transform: translateY(0.000px)}
  87.727% {transform: translateY(-0.167px)}
  88.182% {transform: translateY(0.000px)}
  88.636% {transform: translateY(-0.167px)}
  89.091% {transform: translateY(0.000px)}
  89.545% {transform: translateY(-0.167px)}
  90.000% {transform: translateY(0.000px)}
  90.455% {transform: translateY(-0.167px)}
  90.909% {transform: translateY(0.000px)}
  91.364% {transform: translateY(-0.167px)}
  91.818% {transform: translateY(0.000px)}
  92.273% {transform: translateY(-0.167px)}
  92.727% {transform: translateY(0.000px)}
  93.182% {transform: translateY(-0.167px)}
  93.636% {transform: translateY(0.000px)}
  94.091% {transform: translateY(-0.167px)}
  94.545% {transform: translateY(0.000px)}
  95.000% {transform: translateY(-0.167px)}
  95.455% {transform: translateY(0.000px)}
  95.909% {transform: translateY(-0.167px)}
  96.364% {transform: translateY(0.000px)}
  96.818% {transform: translateY(-0.167px)}
  97.273% {transform: translateY(0.000px)}
  97.727% {transform: translateY(-0.167px)}
  98.182% {transform: translateY(0.000px)}
  98.636% {transform: translateY(-0.167px)}
  99.091% {transform: translateY(0.000px)}
  99.545% {transform: translateY(-0.167px)}
  100.000% {transform: translateY(0.000px)}
}

</style>

<script>
export default {
  name: "Connect4Piece",
  props: {
    // player color of this game piece
    color: String,
  }
}
</script>

