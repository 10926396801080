<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          class="shrink mr-2"
          contain
          :src="require('./assets/connect4logo.png')"
          transition="scale-transition"
          max-height="60"
          width="190"
        />
      </div>
    </v-app-bar>


    <v-main>
        <GamePage/>
    </v-main>
  </v-app>
</template>

<script>
import GamePage from './components/GamePage';

export default {
  name: 'App',

  components: {
    GamePage,
  },

  data: () => ({
    //
  }),
};
</script>
