import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#f3f33c',
                secondary: '#f1e595',
                accent: '#e4242b',
                error: '#000000',
            },
        },
    },
})

export default vuetify;
