<template>
  <v-col class="noselect col-md-6 offset-md-3" style="padding: 0 30px 0 30px">
    <v-row>
      <v-row>
        <v-col class="column" v-on:click="placePiece(0)" :style="humanLock ? 'pointer-events: none' : ''">
          <div class="dropArrow">
          </div>
          <Connect4Piece ref="0-5" :color="gameBoard[0][5]"/>
          <Connect4Piece ref="0-4" :color="gameBoard[0][4]"/>
          <Connect4Piece ref="0-3" :color="gameBoard[0][3]"/>
          <Connect4Piece ref="0-2" :color="gameBoard[0][2]"/>
          <Connect4Piece ref="0-1" :color="gameBoard[0][1]"/>
          <Connect4Piece ref="0-0" v-bind:color="gameBoard[0][0]"/>
          <v-chip class="text-center justify-center columnLabel"
                  elevation="1"
                  label
                  :style="columnHeights[0] >= 6 ? 'background-color: #F44226; pointer-events: none' : 'background-color: #4CAF50'"
          >{{ hintsEnabled ? columnScores[0] : "" }}
          </v-chip>
        </v-col>
        <v-col class="column" v-on:click="placePiece(1)" :style="humanLock ? 'pointer-events: none' : ''">
          <div class="dropArrow">
          </div>
          <Connect4Piece ref="1-5" :color="gameBoard[1][5]"/>
          <Connect4Piece ref="1-4" :color="gameBoard[1][4]"/>
          <Connect4Piece ref="1-3" :color="gameBoard[1][3]"/>
          <Connect4Piece ref="1-2" :color="gameBoard[1][2]"/>
          <Connect4Piece ref="1-1" :color="gameBoard[1][1]"/>
          <Connect4Piece ref="1-0" :color="gameBoard[1][0]"/>
          <v-chip class="text-center justify-center columnLabel"
                  elevation="1"
                  label
                  :style="columnHeights[1] >= 6 ? 'background-color: #F44226; pointer-events: none' : 'background-color: #4CAF50'"
          >{{ hintsEnabled ? columnScores[1] : "" }}
          </v-chip>
        </v-col>
        <v-col class="column" v-on:click="placePiece(2)" :style="humanLock ? 'pointer-events: none' : ''">
          <div class="dropArrow">
          </div>
          <Connect4Piece ref="2-5" :color="gameBoard[2][5]"/>
          <Connect4Piece ref="2-4" :color="gameBoard[2][4]"/>
          <Connect4Piece ref="2-3" :color="gameBoard[2][3]"/>
          <Connect4Piece ref="2-2" :color="gameBoard[2][2]"/>
          <Connect4Piece ref="2-1" :color="gameBoard[2][1]"/>
          <Connect4Piece ref="2-0" :color="gameBoard[2][0]"/>
          <v-chip class="text-center justify-center columnLabel"
                  elevation="1"
                  label
                  :style="columnHeights[2] >= 6 ? 'background-color: #F44226; pointer-events: none' : 'background-color: #4CAF50'"
          >{{ hintsEnabled ? columnScores[2] : "" }}
          </v-chip>
        </v-col>
        <v-col class="column" v-on:click="placePiece(3)" :style="humanLock ? 'pointer-events: none' : ''">
          <div class="dropArrow">
          </div>
          <Connect4Piece ref="3-5" :color="gameBoard[3][5]"/>
          <Connect4Piece ref="3-4" :color="gameBoard[3][4]"/>
          <Connect4Piece ref="3-3" :color="gameBoard[3][3]"/>
          <Connect4Piece ref="3-2" :color="gameBoard[3][2]"/>
          <Connect4Piece ref="3-1" :color="gameBoard[3][1]"/>
          <Connect4Piece ref="3-0" :color="gameBoard[3][0]"/>
          <v-chip class="text-center justify-center columnLabel"
                  elevation="1"
                  label
                  :style="columnHeights[3] >= 6 ? 'background-color: #F44226; pointer-events: none' : 'background-color: #4CAF50'"
          >{{ hintsEnabled ? columnScores[3] : "" }}
          </v-chip>
        </v-col>
        <v-col class="column" v-on:click="placePiece(4)" :style="humanLock ? 'pointer-events: none' : ''">
          <div class="dropArrow">
          </div>
          <Connect4Piece ref="4-5" :color="gameBoard[4][5]"/>
          <Connect4Piece ref="4-4" :color="gameBoard[4][4]"/>
          <Connect4Piece ref="4-3" :color="gameBoard[4][3]"/>
          <Connect4Piece ref="4-2" :color="gameBoard[4][2]"/>
          <Connect4Piece ref="4-1" :color="gameBoard[4][1]"/>
          <Connect4Piece ref="4-0" :color="gameBoard[4][0]"/>
          <v-chip class="text-center justify-center columnLabel"
                  elevation="1"
                  label
                  :style="columnHeights[4] >= 6 ? 'background-color: #F44226; pointer-events: none' : 'background-color: #4CAF50'"
          >{{ hintsEnabled ? columnScores[4] : "" }}
          </v-chip>
        </v-col>
        <v-col class="column" v-on:click="placePiece(5)" :style="humanLock ? 'pointer-events: none' : ''">
          <div class="dropArrow">
          </div>
          <Connect4Piece ref="5-5" :color="gameBoard[5][5]"/>
          <Connect4Piece ref="5-4" :color="gameBoard[5][4]"/>
          <Connect4Piece ref="5-3" :color="gameBoard[5][3]"/>
          <Connect4Piece ref="5-2" :color="gameBoard[5][2]"/>
          <Connect4Piece ref="5-1" :color="gameBoard[5][1]"/>
          <Connect4Piece ref="5-0" :color="gameBoard[5][0]"/>
          <v-chip class="text-center justify-center columnLabel"
                  elevation="1"
                  label
                  :style="columnHeights[5] >= 6 ? 'background-color: #F44226; pointer-events: none' : 'background-color: #4CAF50'"
          >{{ hintsEnabled ? columnScores[5] : "" }}
          </v-chip>
        </v-col>
        <v-col class="column" v-on:click="placePiece(6)" :style="humanLock ? 'pointer-events: none' : ''">
          <div class="dropArrow">
          </div>
          <Connect4Piece ref="6-5" :color="gameBoard[6][5]"/>
          <Connect4Piece ref="6-4" :color="gameBoard[6][4]"/>
          <Connect4Piece ref="6-3" :color="gameBoard[6][3]"/>
          <Connect4Piece ref="6-2" :color="gameBoard[6][2]"/>
          <Connect4Piece ref="6-1" :color="gameBoard[6][1]"/>
          <Connect4Piece ref="6-0" :color="gameBoard[6][0]"/>
          <v-chip class="text-center justify-center columnLabel"
                  elevation="1"
                  label
                  :style="columnHeights[6] >= 6 ? 'background-color: #F44226; pointer-events: none' : 'background-color: #4CAF50'"
          >{{ hintsEnabled ? columnScores[6] : "" }}
          </v-chip>
        </v-col>
      </v-row>
    </v-row>

    <v-row fluid style="margin-left: 4%; margin-right: 4% ;width: 100%;">
      <v-row>
        <v-col>
          <v-btn
              v-on:click="resetBoard"
          >Reset
          </v-btn>
        </v-col>

        <v-col v-if="onLine">
          <v-checkbox
              v-bind:disabled="playerWon"
              v-model="AIEnabled"
              label="Yellow AI"
              color="yellow"
          ></v-checkbox>
          <v-checkbox
              v-bind:disabled="playerWon"
              v-model="hintsEnabled"
              label="Enable Hints"
              color="blue"
          ></v-checkbox>
          <v-checkbox
              v-bind:disabled="playerWon"
              v-model="fastAI"
              label="Fast AI"
              color="green"
          ></v-checkbox>

        </v-col>

        <v-col v-else>
          <v-chip>
            (Go online to enable Yellow AI)
          </v-chip>
        </v-col>

        <v-col>
          <v-chip
              label
              v-if="!playerWon"
              :style="playerTurn === 'Yellow' ? 'background-color: #F3F33C;' : 'background-color: #DE0D07;'"
          >
            Player Turn: {{ playerTurn }}
          </v-chip>

          <v-chip
              label
              v-if="playerWon"
              :style="playerTurn === 'Yellow' ? 'background-color: #F3F33C;' : 'background-color: #DE0D07;'"
          >
            {{ playerTurn }} player won!!!
          </v-chip>

          <div
              style="padding-top: 10px"
              v-if="hintsEnabled"
          ><p>positive number: winning move</p>
            <p>0: draw move</p>
            <p>negative number: losing move</p>
          </div>

        </v-col>
      </v-row>

    </v-row>

  </v-col>

</template>

<style>

.noselect, .column, img {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  /* Not implemented yet */
  user-select: none;
}

.columnLabel {
  width: 100%;
  height: 40px;
  margin-bottom: 100%;
}

.column {
  padding: 0 6px 6px 6px !important;
  width: calc(100 / 7 * 100%);
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}

.column:hover {
  background-color: #EEE;
}

.dropArrow {
  width: 100%;
  height: 10%;
  position: relative;
  margin-bottom: 10px;
  display: block;
  margin-top: 15px;

}

.column:hover .dropArrow {
  /*background-color: green;*/
  background-image: url("../assets/dropArrow.gif");
  background-size: contain;
  background-position: center center;
  opacity: 25%;
}

</style>

<script>
import Connect4Piece from "@/components/Connect4Piece";

const rp = require('request-promise');

export default {
  name: 'GamePage',
  components: {Connect4Piece},
  data: () => ({

    // stores online status of client
    onLine: navigator.onLine,

    // current player turn (Red | Yellow)
    playerTurn: "Red",

    // disallows game board interaction
    humanLock: false,

    // Stores game state in 2d array (red, yellow, "")
    gameBoard: [
      ["", "", "", "", "", ""],
      ["", "", "", "", "", ""],
      ["", "", "", "", "", ""],
      ["", "", "", "", "", ""],
      ["", "", "", "", "", ""],
      ["", "", "", "", "", ""],
      ["", "", "", "", "", ""]],

    // highest game piece in each column
    columnHeights: [0, 0, 0, 0, 0, 0, 0],

    // order of columns players drop game pieces into
    moveRecord: "",

    playerWon: false,

    // column scores indicated by outsourced AI
    columnScores: [0, 0, 0, 0, 0, 0, 0],

    AIEnabled: false,

    hintsEnabled: false,

    // makes the AI play faster
    fastAI: false,
  }),
  methods: {
    updateOnlineStatus(e) {
      const {type} = e;
      this.onLine = type === 'online';
    },

    // Changes the color of a Connect4Piece by reference
    changeColor: function (column, row, color) {
      this.$set(this.gameBoard[column], row, color)
    },

    // Resets the game state to the same as when the component was loaded
    resetBoard: function () {

      // reset gameboard
      for (let i = 0; i < this.gameBoard.length; i++) {
        for (let j = 0; j < this.gameBoard[1].length; j++) {
          this.changeColor(i, j, "");
        }
      }

      // reset player turn
      this.playerTurn = "Red";

      // reset column heights
      for (let i = 0; i < 7; i++) {
        this.columnHeights[i] = 0;
      }

      // reset column scores
      for (let i = 0; i < 7; i++) {
        this.columnScores[i] = 0;
      }

      // reset move record
      this.moveRecord = "";

      // reset player won status
      this.playerWon = false;

      // reset human lock
      this.humanLock = false;

      // disable hints
      this.hintsEnabled = false;

      // disable Yellow AI
      this.AIEnabled = false;

      // disable fast AI
      this.fastAI = false;

      this.updateColumnScores();

      // force update vue to make sure the game state is fully reset
      this.$forceUpdate()
    },

    // Event trigger for when pieces are placed, manages game logic
    placePiece: function (column) {
      // If the AI tries to place a piece stop the human!
      // (disallows human interaction while the AI is working)
      if (this.AIEnabled) {
        this.humanLock = true;
        console.log("checks for human")
      }

      // if no player won and the column has a valid spot,
      // place piece, update column scores, and switch player turn
      if (this.playerWon === false && this.columnHeights[column] < 6) {
        this.changeColor(column, this.columnHeights[column], this.playerTurn === "Red" ? "red" : "yellow");
        this.columnHeights[column]++;

        // update game state move record
        this.moveRecord = this.moveRecord.concat(column + 1);

        // checks if a player won and modifies the playerWon status
        this.playerWon = this.are4Connected(this.playerTurn === "Red" ? "red" : "yellow");

        // if the player hasn't won, continue the game and switch turns
        if (this.playerWon === false) {
          // switches active player turn
          if (this.playerTurn === "Red") {
            this.playerTurn = "Yellow";
            // check if a player won
          } else {
            this.playerTurn = "Red"
            // check if a player won
          }

          // update the column score hints for the current players turn
          this.updateColumnScores();
        }
      }
    },

    // checks if there are 4 connected game pieces anywhere on the board for the given color player
    are4Connected: function (color) {
      //Credit: https://stackoverflow.com/a/38211417

      // horizontal gameBoard check
      for (let j = 0; j < this.gameBoard.length - 3; j++) {
        for (let i = 0; i < this.gameBoard[1].length; i++) {
          if (this.gameBoard[i][j] === color && this.gameBoard[i][j + 1] === color && this.gameBoard[i][j + 2] === color && this.gameBoard[i][j + 3] === color) {
            return true;
          }
        }
      }
      // vertical gameBoard check
      for (let i = 0; i < this.gameBoard.length - 3; i++) {
        for (let j = 0; j < this.gameBoard[1].length; j++) {
          if (this.gameBoard[i][j] === color && this.gameBoard[i + 1][j] === color && this.gameBoard[i + 2][j] === color && this.gameBoard[i + 3][j] === color) {
            return true;
          }
        }
      }
      // ascending diagonal gameBoard check
      for (let i = 3; i < this.gameBoard.length; i++) {
        for (let j = 0; j < this.gameBoard[1].length - 3; j++) {
          if (this.gameBoard[i][j] === color && this.gameBoard[i - 1][j + 1] === color && this.gameBoard[i - 2][j + 2] === color && this.gameBoard[i - 3][j + 3] === color)
            return true;
        }
      }
      // descending diagonal gameBoard check
      for (let i = 3; i < this.gameBoard.length; i++) {
        for (let j = 3; j < this.gameBoard[1].length; j++) {
          if (this.gameBoard[i][j] === color && this.gameBoard[i - 1][j - 1] === color && this.gameBoard[i - 2][j - 2] === color && this.gameBoard[i - 3][j - 3] === color)
            return true;
        }
      }
      return false;
    },

    // performs http request to attain column scores for the given game state
    updateColumnScores: function () {
      // base url + client game state
      const url = 'https://connect4.gamesolver.org/solve?pos=' + this.moveRecord;

      // async http request to gamesolver url
      rp(url).then((html) => {
        // parse http response as JSON
        let response = JSON.parse(html);

        // extract column scores from html response
        this.columnScores = response.score;

        // If the ai is enabled and it's yellow's turn place a piece automatically after calculating column scores
        if (this.AIEnabled && this.playerTurn === "Yellow") {

          // find and save highest score column in gamesolver response
          let column = this.findHigestNumber(response.score);

          // if the AI be speedy don't add delay.. (usually premium upgrade)
          if (this.fastAI) {
            // simulate a human clicking a column and place a piece on the board
            this.placePiece(column);
            this.humanLock = false;
          } else {
            setTimeout(() => {
              // simulate a human clicking a column and place a piece on the board
              this.placePiece(column);
              this.humanLock = false;
            }, 1500);
          }
        }
      })
          // eslint-disable-next-line no-unused-vars
          .catch(function (err) {
            //handle error
          });
    },

    // return the index of the highest number in a given array
    findHigestNumber: function (nums) {

      let highestNum = -100
      let indexOfHighestNum = 0;

      for (let i = 0; i < nums.length; i++) {
        if (nums[i] > highestNum && nums[i] !== 100) {
          highestNum = nums[i];
          indexOfHighestNum = i;
        }
      }

      return indexOfHighestNum;

    },
  },
  mounted: function () {

    // performs initial requisition of column scores before any moves are made
    this.$nextTick(() => {
      this.updateColumnScores();
    })

    // adds listeners so we know when the client is offline
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  watch: {

    // Automatically executes moves when it's the Yellow Players turn
    AIEnabled: function () {
      if (this.AIEnabled && this.playerTurn === "Yellow") {
        this.updateColumnScores();
      }
    },

    // Disables the AI options and AI when the client is offline
    onLine(v) {
      if (!v) {
        this.AIEnabled = false;
        this.humanLock = false;
      }
    },

    // clean and tidy
    beforeDestroy() {
      window.removeEventListener('online', this.updateOnlineStatus);
      window.removeEventListener('offline', this.updateOnlineStatus);
    }
  }
}
</script>
